import { Box, Text } from "grommet";
import React, { useState } from "react";
import { Checkmark } from "grommet-icons";
import CustomInput from "../../../elements/custom_input";
import CustomSelect from "../../../elements/select_fill";
import PrimaryButton from "../../../elements/primary_button";
import { hasAdminRole } from "../../../../services/storage_service";
import DateInput from "../../../elements/date_input";


const BanqueInfoRac = ({
  devis,
  updateDevis,
  onSave,
}) => {

  const [dataChanged, setDataChanged] = useState(false);

  const updateData = (field, value) => {
    setDataChanged(true);
    updateDevis(field, value);
  }



  const SectionHeader = ({ title, color, round }) => {
    return (
      <Box fill gap="small" pad='xxsmall' background={color || 'brand'} style={round ? { borderRadius: 12 } : {}}>
        <Text color='white' style={{ fontWeight: "bold", marginLeft: 20 }}>
          {title}
        </Text>
      </Box>
    )
  }

  const submitModification = () => {
    setDataChanged(false);
    onSave({ skipValidation: true });
  }

  return (

    <Box fill background={"white"}>
      {dataChanged && <Box fill background={'yellow'} justify="end" direction="row" pad='xxsmall'>
        <PrimaryButton background="transparent" icon={<Checkmark color="green" />} reverse onClick={submitModification} label={'Valider'} color='green'></PrimaryButton>
      </Box>}
      <SectionHeader title={"Information de l’offre"} />
      <Box pad={'small'} gap='small'>
        {/* <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text fill>Produit</Text></Box>
          <Box fill><CustomSelect

            size={'small'}
            options={[
              "Crédit Immobilier",
              "Regroupement de crédit",
              "Crédit à la consommation"
            ]}
            value={devis.sub_product}
            onChange={({ option }) => updateData('sub_product', option)}
          /></Box>
        </Box> */}

        <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text  >Produit info</Text></Box>
          <Box fill>
            <CustomSelect
              placeholder='Produit info'
              options={['Regroupement de crédits', 'Regroupement de crédits projet']}
              value={devis.sub_product_detail}
              onChange={({ option }) => {
                updateData("sub_product_detail", option);
              }} />
          </Box>
        </Box>

        <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text  >Banque</Text></Box>
          <Box fill>
            <CustomSelect
              placeholder='Banque'
              options={['Créatis', 'CGI', 'CMB', 'CML', 'CKV', 'CFCAL', 'MyMoneyBank', 'SYGMA', 'CréditLift']}
              value={devis.banque}
              onChange={({ option }) => {
                updateData("banque", option);
              }} />
          </Box>
        </Box>

        <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text  >Montant emprunté</Text></Box>
          <Box fill>
            <CustomInput
              value={devis.emprunte_total}
              onChange={(ev) => updateData("emprunte_total", ev.target.value)}
              icon={<Text>€</Text>}
              reverse
              type='number'
              step={0.01}
            /></Box>
        </Box>

        <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text  >Nombre mois</Text></Box>
          <Box fill>
            <CustomInput
              value={devis.duration_expected}
              onChange={(ev) => updateData("duration_expected", ev.target.value)}
              icon={<Text>mois</Text>}
              type='number'
              step={0.01}
              reverse
            /></Box>
        </Box>


        <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text  >Taux</Text></Box>
          <Box fill>
            <CustomInput
              value={devis.taux_regroupement}
              onChange={(ev) => updateData("taux_regroupement", ev.target.value)}
              icon={<Text>%</Text>}
              reverse
              type='number'
              step={0.01}
            /></Box>
        </Box>
      </Box>
      <SectionHeader title={"Comptabilité"} />
      <Box pad={'small'} gap='small'>
        <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text  >Frais de dossier</Text></Box>
          <Box fill>
            <CustomInput
              value={devis.frais_dossier}
              onChange={(ev) => updateData("frais_dossier", ev.target.value)}
              icon={<Text>€</Text>}
              reverse
              type='number'
              step={0.01}
            /></Box>
        </Box>

        <Box direction='row' gap='small' width={'large'} justify='between'>
          <Box fill><Text  >Commission <b>(Assurance + Crédit)</b></Text></Box>
          <Box fill >
            <CustomInput
              value={devis.commission_total}
              onChange={(ev) => updateData("commission_total", ev.target.value)}
              icon={<Text>€</Text>}
              reverse
              type='number'
              step={0.01}
            /></Box>
        </Box>

        {hasAdminRole() && (
          <Box direction='row' gap='small' width={'large'} justify='between'>
            <Box fill><Text  >Date réalisation</Text></Box>
            <Box fill >
              <DateInput size='small'
                value={devis.date_realisation}
                onChange={(date) => updateData('date_realisation', date)}
                style={{ background: '#EDEDED' }}
                calendarProps={{
                  locale: "fr-FR",
                  size: "small",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>


    </Box >
  );
};

export default BanqueInfoRac;
